.container {
  padding: var(--space-4) var(--space-3) 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--color-background-main);
  z-index: 2;
  width: 100%;
  position: sticky !important;
  top: calc(var(--header-height) - 76px);
}

.title {
  font-weight: 700;
  margin-bottom: var(--space-3);
}

.border {
  border-bottom: 1px solid var(--color-border-light);
}

.pageHeader,
.actionsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actionsWrapper {
  gap: var(--space-1);
}

@media (max-width: 599.95px) {
  .container {
    padding: var(--space-3) var(--space-2) 0;
  }

  .border {
    border: 0;
  }

  .pageHeader {
    flex-direction: column;
    align-items: flex-start;
    gap: var(--space-3);
  }

  .navWrapper {
    border-bottom: 1px solid var(--color-border-light);
    margin-left: calc(var(--space-2) * -1);
    padding-left: var(--space-2);
    margin-right: calc(var(--space-2) * -1);
    padding-right: var(--space-2);
    align-self: stretch;
  }

  .actionsWrapper {
    padding-bottom: 16px;
  }
}
