.button {
  justify-content: space-between;
  padding: var(--space-2);
  border-radius: 6px;
  border: 1px solid var(--color-border-light);
  width: 100%;
  color: var(--color-text-primary);
}

.button:hover {
  border-color: var(--color-primary-main);
}

@media (max-width: 400px) {
  .buyCryptoButton {
    width: 100%;
  }
}
